<template>
  <div>
    <uds-simple-dropdown>
      <template #inputContent>
        <uds-input-string
          label="Raison sociale ou Prénom + Nom"
          :value="selectedItem"
          :error="error"
          is-required
          style="position: relative; flex-basis: 100%; flex-grow: 1"
          @input-spe="debounceSearch($event), $emit('update', $event)"
        />
        <div v-if="isLoading" class="input-loading-bar" />
      </template>
      <template #dropdownContent="{ hide }">
        <CommonsDropdownsLegalNameDropdown
          v-if="!isLoading"
          :siren-values="sirenValues"
          @select="$emit('select', $event), hide()"
          @close="hide()"
        />
      </template>
    </uds-simple-dropdown>
  </div>
</template>

<script setup lang="ts">
import type { LegalFormSiren } from "@silexpert/core";
import debounce from "lodash-es/debounce";

defineProps<{
  selectedItem: string | null | undefined;
  error: string | null;
}>();

defineEmits(["select", "update"]);

const isLoading = ref<boolean>(false);
const sirenValues = ref<LegalFormSiren[]>([]);

const debounceSearch = debounce(triggerSearch, 500);

async function triggerSearch(event: string) {
  isLoading.value = true;
  await $silex()
    .society.getDataSociety({ search: event })
    .then((res: LegalFormSiren[]) => {
      sirenValues.value = res;
    })
    .catch((error: any) => {
      sirenValues.value = [];
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });
  isLoading.value = false;
}
</script>

<style lang="scss" scoped>
.uds-input-balise {
  &:hover {
    border: 1px solid $uds-primary-500;
  }
  &:focus {
    border: 1px solid $uds-primary-500;
  }
}

.input-loading-bar {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: $uds-primary-500;
  bottom: 1px;
  animation: loading-bar 1s ease infinite alternate;
}
@keyframes loading-bar {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(100%);
  }
}
</style>
