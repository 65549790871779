<template>
  <div class="legal-name-dropdown-container">
    <div v-if="sirenValues.length > 0" class="legal-name-dropdown-list scrollbar">
      <div
        v-for="(item, index) in sirenValues"
        :key="index"
        class="lnd-line"
        @click="$emit('select', item)"
      >
        <div>
          {{ item.l1_normalisee }}
        </div>
        <div class="description-siren">{{ item.libcom }} {{ item.codpos }}</div>
      </div>
    </div>
    <div v-else-if="!isLoading" class="legal-name-dropdown-bottom">
      Aucun résultat ne correspond à votre recherche...
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LegalFormSiren } from "@silexpert/core";

defineProps<{
  sirenValues: LegalFormSiren[];
}>();

defineEmits<{
  (e: "close"): void;
  (e: "select", id: LegalFormSiren): void;
}>();

const isLoading = ref<boolean>(false);
</script>

<style lang="scss" scoped>
.legal-name-dropdown-container {
  width: 400px;
  box-shadow: 0px 6px 16px rgba(32, 33, 36, 0.12);
  border-radius: $uds-radius-1;
  background: $uds-white;

  // on every direct child
  & > * {
    border-bottom: 1px solid $uds-neutral-500;

    &:last-child {
      border-bottom: 0px;
    }
  }

  .legal-name-dropdown-list {
    height: 200px;
    .lnd-line {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: $uds-spacing-0_5;
      padding: $uds-spacing-1_5 $uds-spacing-2;
      box-sizing: border-box;

      &:hover {
        background-color: $uds-neutral-100;
        cursor: pointer;
      }
      .description-siren {
        color: $uds-neutral-800;
        margin-left: 3px;
        font-size: 14px;
      }
    }
  }

  .legal-name-dropdown-bottom {
    height: 40px;
    margin-left: $uds-spacing-1;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
